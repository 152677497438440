// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable max-lines */
import { type ForwardedRef, forwardRef } from "react";

import { formatDateWithFullMonth } from "../lib/date";

// This is HTML generated from a Google Doc with the BAA: https://docs.google.com/document/d/18k44_ldrSw-zJetRzjGr5b0sBO7Dxmbc/edit
// Do not edit.

/**
 * Guide for converting the Google doc to HTML:
 *
 * 1. Open the Google doc.
 * 2. Select "File -> Download -> Web Page (.html, zipped)" from the menu.
 * 3. Unzip the downloaded file.
 * 4. Open the unzipped HTML file for editing.
 * 5. Copy the content of the HTML file's "style" tag to the "css' variable in this file.
 * 6. In the HTML file, remove the "html", "head", "meta" and "body" tags, while still keeping the content inside the "body" tag.
 * 7. In this file, remove the children of the top level "div" except for the "style" tag.
 * 8. Place the content of the HTML file inside the "div".
 * 9. You don't need the HTML file anymore, you can close it.
 * 10. Find the contractor "Print Name" near the end of the HTML and render the "contractorName" prop there.
 * 11. Find the contractor signature blank spaces (&nbsp;&nbsp;&nbsp;) near the print name and place the "contractorSignatureImage" variable there.
 * 12. Find the contractor "Print Name" near the end of the HTML and place "currentDate" variable there.
 * 13. Find the parenting element (usually a "td") of the contractor signature and add "style={{ position: "relative"}}"" to it.
 * 14. Find all occurrences of the "\002022" css and replace with "\u002022"
 * 15. Find all occurrences of the "start" attribute and replace their values with numbers instead of strings.
 * 16. Find all occurrences of the "colSpan" and "rowSpan" attributes and replace their values with numbers instead of strings.
 * 17. Find all empty anchor elements and remove them (ESLint should point them out as erroneous).
 * 18. Find any "table" elements with no "tbody" children and add those otherwise there will be errors in the browser.
 * 19. Check if eslint is pointing out any other errors and document how to fix them here.
 * 20. Check if document is rendering correctly in browser and there are any errors in the console. Document how to fix them here.
 * 21. Increment BAA_VERSION and notify the Home Health team about the change.
 */

export const BAA_VERSION = "v1";

const css = `
.lst-kix_list_4-1 > li {
  counter-increment: lst-ctn-kix_list_4-1;
}
ol.lst-kix_list_7-0 {
  list-style-type: none;
}
.lst-kix_list_2-1 > li {
  counter-increment: lst-ctn-kix_list_2-1;
}
ol.lst-kix_list_9-0.start {
  counter-reset: lst-ctn-kix_list_9-0 0;
}
.lst-kix_list_6-1 > li {
  counter-increment: lst-ctn-kix_list_6-1;
}
ol.lst-kix_list_3-1.start {
  counter-reset: lst-ctn-kix_list_3-1 0;
}
ol.lst-kix_list_6-6.start {
  counter-reset: lst-ctn-kix_list_6-6 0;
}
ol.lst-kix_list_7-4.start {
  counter-reset: lst-ctn-kix_list_7-4 0;
}
.lst-kix_list_5-0 > li {
  counter-increment: lst-ctn-kix_list_5-0;
}
.lst-kix_list_7-0 > li {
  counter-increment: lst-ctn-kix_list_7-0;
}
.lst-kix_list_9-0 > li {
  counter-increment: lst-ctn-kix_list_9-0;
}
ol.lst-kix_list_2-3.start {
  counter-reset: lst-ctn-kix_list_2-3 0;
}
ol.lst-kix_list_7-5 {
  list-style-type: none;
}
ul.lst-kix_list_1-3 {
  list-style-type: none;
}
ol.lst-kix_list_7-6 {
  list-style-type: none;
}
ul.lst-kix_list_1-4 {
  list-style-type: none;
}
ol.lst-kix_list_7-7 {
  list-style-type: none;
}
ol.lst-kix_list_7-8 {
  list-style-type: none;
}
ol.lst-kix_list_7-1 {
  list-style-type: none;
}
ul.lst-kix_list_1-7 {
  list-style-type: none;
}
ol.lst-kix_list_7-2 {
  list-style-type: none;
}
ul.lst-kix_list_1-8 {
  list-style-type: none;
}
ol.lst-kix_list_7-3 {
  list-style-type: none;
}
ul.lst-kix_list_1-5 {
  list-style-type: none;
}
ol.lst-kix_list_7-4 {
  list-style-type: none;
}
ul.lst-kix_list_1-6 {
  list-style-type: none;
}
ol.lst-kix_list_9-6.start {
  counter-reset: lst-ctn-kix_list_9-6 0;
}
ol.lst-kix_list_5-3.start {
  counter-reset: lst-ctn-kix_list_5-3 0;
}
.lst-kix_list_2-3 > li {
  counter-increment: lst-ctn-kix_list_2-3;
}
.lst-kix_list_4-3 > li {
  counter-increment: lst-ctn-kix_list_4-3;
}
ol.lst-kix_list_4-5.start {
  counter-reset: lst-ctn-kix_list_4-5 0;
}
.lst-kix_list_1-2 > li {
  counter-increment: lst-ctn-kix_list_1-2;
}
ol.lst-kix_list_3-7.start {
  counter-reset: lst-ctn-kix_list_3-7 0;
}
.lst-kix_list_5-2 > li {
  counter-increment: lst-ctn-kix_list_5-2;
}
.lst-kix_list_3-2 > li {
  counter-increment: lst-ctn-kix_list_3-2;
}
.lst-kix_list_7-2 > li {
  counter-increment: lst-ctn-kix_list_7-2;
}
.lst-kix_list_9-2 > li {
  counter-increment: lst-ctn-kix_list_9-2;
}
.lst-kix_list_5-0 > li:before {
  content: '' counter(lst-ctn-kix_list_5-0, decimal) '. ';
}
ol.lst-kix_list_6-0 {
  list-style-type: none;
}
ol.lst-kix_list_6-1 {
  list-style-type: none;
}
.lst-kix_list_5-4 > li {
  counter-increment: lst-ctn-kix_list_5-4;
}
.lst-kix_list_5-3 > li:before {
  content: '(' counter(lst-ctn-kix_list_5-3, decimal) ') ';
}
ol.lst-kix_list_9-5.start {
  counter-reset: lst-ctn-kix_list_9-5 0;
}
.lst-kix_list_5-2 > li:before {
  content: '(' counter(lst-ctn-kix_list_5-2, lower-roman) ') ';
}
.lst-kix_list_5-1 > li:before {
  content: '(' counter(lst-ctn-kix_list_5-1, lower-latin) ') ';
}
.lst-kix_list_5-7 > li:before {
  content: '' counter(lst-ctn-kix_list_5-7, lower-latin) '. ';
}
ul.lst-kix_list_8-4 {
  list-style-type: none;
}
ul.lst-kix_list_8-5 {
  list-style-type: none;
}
.lst-kix_list_5-6 > li:before {
  content: '' counter(lst-ctn-kix_list_5-6, decimal) '. ';
}
.lst-kix_list_5-8 > li:before {
  content: '' counter(lst-ctn-kix_list_5-8, lower-roman) '. ';
}
ul.lst-kix_list_8-2 {
  list-style-type: none;
}
ul.lst-kix_list_8-3 {
  list-style-type: none;
}
ul.lst-kix_list_8-8 {
  list-style-type: none;
}
ul.lst-kix_list_8-6 {
  list-style-type: none;
}
ul.lst-kix_list_8-7 {
  list-style-type: none;
}
.lst-kix_list_9-4 > li {
  counter-increment: lst-ctn-kix_list_9-4;
}
ol.lst-kix_list_6-6 {
  list-style-type: none;
}
ol.lst-kix_list_6-7 {
  list-style-type: none;
}
.lst-kix_list_5-4 > li:before {
  content: '(' counter(lst-ctn-kix_list_5-4, lower-latin) ') ';
}
ol.lst-kix_list_6-8 {
  list-style-type: none;
}
.lst-kix_list_5-5 > li:before {
  content: '(' counter(lst-ctn-kix_list_5-5, lower-roman) ') ';
}
ol.lst-kix_list_6-2 {
  list-style-type: none;
}
ol.lst-kix_list_6-3 {
  list-style-type: none;
}
ul.lst-kix_list_8-1 {
  list-style-type: none;
}
ol.lst-kix_list_6-4 {
  list-style-type: none;
}
ol.lst-kix_list_6-5 {
  list-style-type: none;
}
ol.lst-kix_list_1-0.start {
  counter-reset: lst-ctn-kix_list_1-0 0;
}
.lst-kix_list_6-1 > li:before {
  content: '(' counter(lst-ctn-kix_list_6-1, lower-latin) ') ';
}
.lst-kix_list_6-3 > li:before {
  content: '(' counter(lst-ctn-kix_list_6-3, decimal) ') ';
}
.lst-kix_list_6-5 > li {
  counter-increment: lst-ctn-kix_list_6-5;
}
.lst-kix_list_6-8 > li {
  counter-increment: lst-ctn-kix_list_6-8;
}
.lst-kix_list_6-0 > li:before {
  content: '' counter(lst-ctn-kix_list_6-0, decimal) '. ';
}
.lst-kix_list_6-4 > li:before {
  content: '(' counter(lst-ctn-kix_list_6-4, lower-latin) ') ';
}
.lst-kix_list_3-0 > li {
  counter-increment: lst-ctn-kix_list_3-0;
}
ol.lst-kix_list_4-0.start {
  counter-reset: lst-ctn-kix_list_4-0 0;
}
.lst-kix_list_3-6 > li {
  counter-increment: lst-ctn-kix_list_3-6;
}
.lst-kix_list_6-2 > li:before {
  content: '(' counter(lst-ctn-kix_list_6-2, lower-roman) ') ';
}
.lst-kix_list_2-5 > li {
  counter-increment: lst-ctn-kix_list_2-5;
}
.lst-kix_list_2-8 > li {
  counter-increment: lst-ctn-kix_list_2-8;
}
ol.lst-kix_list_3-2.start {
  counter-reset: lst-ctn-kix_list_3-2 0;
}
.lst-kix_list_6-8 > li:before {
  content: '' counter(lst-ctn-kix_list_6-8, lower-roman) '. ';
}
.lst-kix_list_6-5 > li:before {
  content: '(' counter(lst-ctn-kix_list_6-5, lower-roman) ') ';
}
.lst-kix_list_6-7 > li:before {
  content: '' counter(lst-ctn-kix_list_6-7, lower-latin) '. ';
}
ol.lst-kix_list_2-4.start {
  counter-reset: lst-ctn-kix_list_2-4 0;
}
.lst-kix_list_6-6 > li:before {
  content: '' counter(lst-ctn-kix_list_6-6, decimal) '. ';
}
.lst-kix_list_2-7 > li:before {
  content: '' counter(lst-ctn-kix_list_2-7, lower-latin) '. ';
}
.lst-kix_list_2-7 > li {
  counter-increment: lst-ctn-kix_list_2-7;
}
.lst-kix_list_7-4 > li:before {
  content: '(' counter(lst-ctn-kix_list_7-4, lower-latin) ') ';
}
.lst-kix_list_7-6 > li:before {
  content: '' counter(lst-ctn-kix_list_7-6, decimal) '. ';
}
ol.lst-kix_list_1-0 {
  list-style-type: none;
}
.lst-kix_list_2-5 > li:before {
  content: '' counter(lst-ctn-kix_list_2-5, lower-roman) '. ';
}
ol.lst-kix_list_6-2.start {
  counter-reset: lst-ctn-kix_list_6-2 0;
}
ol.lst-kix_list_1-1 {
  list-style-type: none;
}
ol.lst-kix_list_1-2 {
  list-style-type: none;
}
.lst-kix_list_7-2 > li:before {
  content: '(' counter(lst-ctn-kix_list_7-2, lower-roman) ') ';
}
.lst-kix_list_7-6 > li {
  counter-increment: lst-ctn-kix_list_7-6;
}
ol.lst-kix_list_9-4.start {
  counter-reset: lst-ctn-kix_list_9-4 0;
}
ol.lst-kix_list_4-6.start {
  counter-reset: lst-ctn-kix_list_4-6 0;
}
ol.lst-kix_list_9-7 {
  list-style-type: none;
}
ol.lst-kix_list_9-8 {
  list-style-type: none;
}
ol.lst-kix_list_3-0.start {
  counter-reset: lst-ctn-kix_list_3-0 0;
}
ol.lst-kix_list_9-3 {
  list-style-type: none;
}
ol.lst-kix_list_9-4 {
  list-style-type: none;
}
.lst-kix_list_5-7 > li {
  counter-increment: lst-ctn-kix_list_5-7;
}
.lst-kix_list_7-7 > li {
  counter-increment: lst-ctn-kix_list_7-7;
}
ol.lst-kix_list_9-5 {
  list-style-type: none;
}
.lst-kix_list_7-8 > li:before {
  content: '' counter(lst-ctn-kix_list_7-8, lower-roman) '. ';
}
ol.lst-kix_list_9-6 {
  list-style-type: none;
}
ol.lst-kix_list_4-3.start {
  counter-reset: lst-ctn-kix_list_4-3 0;
}
.lst-kix_list_4-7 > li {
  counter-increment: lst-ctn-kix_list_4-7;
}
ol.lst-kix_list_9-0 {
  list-style-type: none;
}
ol.lst-kix_list_9-1 {
  list-style-type: none;
}
ol.lst-kix_list_9-2 {
  list-style-type: none;
}
.lst-kix_list_7-8 > li {
  counter-increment: lst-ctn-kix_list_7-8;
}
ol.lst-kix_list_2-5.start {
  counter-reset: lst-ctn-kix_list_2-5 0;
}
.lst-kix_list_9-8 > li {
  counter-increment: lst-ctn-kix_list_9-8;
}
.lst-kix_list_2-6 > li {
  counter-increment: lst-ctn-kix_list_2-6;
}
.lst-kix_list_4-1 > li:before {
  content: '(' counter(lst-ctn-kix_list_4-1, lower-latin) ') ';
}
ol.lst-kix_list_7-3.start {
  counter-reset: lst-ctn-kix_list_7-3 0;
}
.lst-kix_list_9-2 > li:before {
  content: '(' counter(lst-ctn-kix_list_9-2, lower-roman) ') ';
}
.lst-kix_list_4-3 > li:before {
  content: '(' counter(lst-ctn-kix_list_4-3, decimal) ') ';
}
.lst-kix_list_4-5 > li:before {
  content: '(' counter(lst-ctn-kix_list_4-5, lower-roman) ') ';
}
ol.lst-kix_list_5-7.start {
  counter-reset: lst-ctn-kix_list_5-7 0;
}
.lst-kix_list_5-5 > li {
  counter-increment: lst-ctn-kix_list_5-5;
}
.lst-kix_list_3-5 > li {
  counter-increment: lst-ctn-kix_list_3-5;
}
ol.lst-kix_list_1-1.start {
  counter-reset: lst-ctn-kix_list_1-1 0;
}
.lst-kix_list_9-0 > li:before {
  content: '' counter(lst-ctn-kix_list_9-0, decimal) '. ';
}
.lst-kix_list_3-4 > li {
  counter-increment: lst-ctn-kix_list_3-4;
}
ol.lst-kix_list_4-4.start {
  counter-reset: lst-ctn-kix_list_4-4 0;
}
.lst-kix_list_6-4 > li {
  counter-increment: lst-ctn-kix_list_6-4;
}
ol.lst-kix_list_9-2.start {
  counter-reset: lst-ctn-kix_list_9-2 0;
}
.lst-kix_list_9-6 > li:before {
  content: '' counter(lst-ctn-kix_list_9-6, decimal) '. ';
}
.lst-kix_list_9-3 > li {
  counter-increment: lst-ctn-kix_list_9-3;
}
.lst-kix_list_9-4 > li:before {
  content: '(' counter(lst-ctn-kix_list_9-4, lower-latin) ') ';
}
.lst-kix_list_6-3 > li {
  counter-increment: lst-ctn-kix_list_6-3;
}
ol.lst-kix_list_2-8.start {
  counter-reset: lst-ctn-kix_list_2-8 0;
}
ol.lst-kix_list_1-2.start {
  counter-reset: lst-ctn-kix_list_1-2 0;
}
ol.lst-kix_list_7-6.start {
  counter-reset: lst-ctn-kix_list_7-6 0;
}
ol.lst-kix_list_6-1.start {
  counter-reset: lst-ctn-kix_list_6-1 0;
}
ol.lst-kix_list_8-0 {
  list-style-type: none;
}
.lst-kix_list_9-8 > li:before {
  content: '' counter(lst-ctn-kix_list_9-8, lower-roman) '. ';
}
.lst-kix_list_1-1 > li:before {
  content: '' counter(lst-ctn-kix_list_1-1, decimal) '. ';
}
.lst-kix_list_1-3 > li:before {
  content: '\u002022   ';
}
.lst-kix_list_4-8 > li {
  counter-increment: lst-ctn-kix_list_4-8;
}
.lst-kix_list_1-7 > li:before {
  content: '\u002022   ';
}
ol.lst-kix_list_5-8.start {
  counter-reset: lst-ctn-kix_list_5-8 0;
}
ol.lst-kix_list_2-7.start {
  counter-reset: lst-ctn-kix_list_2-7 0;
}
.lst-kix_list_1-5 > li:before {
  content: '\u002022   ';
}
ol.lst-kix_list_9-1.start {
  counter-reset: lst-ctn-kix_list_9-1 0;
}
.lst-kix_list_5-6 > li {
  counter-increment: lst-ctn-kix_list_5-6;
}
ol.lst-kix_list_7-5.start {
  counter-reset: lst-ctn-kix_list_7-5 0;
}
.lst-kix_list_2-1 > li:before {
  content: '' counter(lst-ctn-kix_list_2-1, lower-latin) '. ';
}
ol.lst-kix_list_6-0.start {
  counter-reset: lst-ctn-kix_list_6-0 0;
}
.lst-kix_list_2-3 > li:before {
  content: '' counter(lst-ctn-kix_list_2-3, decimal) '. ';
}
.lst-kix_list_4-2 > li {
  counter-increment: lst-ctn-kix_list_4-2;
}
ol.lst-kix_list_3-1 {
  list-style-type: none;
}
ol.lst-kix_list_3-2 {
  list-style-type: none;
}
.lst-kix_list_3-1 > li {
  counter-increment: lst-ctn-kix_list_3-1;
}
ol.lst-kix_list_3-3 {
  list-style-type: none;
}
ol.lst-kix_list_3-4.start {
  counter-reset: lst-ctn-kix_list_3-4 0;
}
.lst-kix_list_5-1 > li {
  counter-increment: lst-ctn-kix_list_5-1;
}
ol.lst-kix_list_3-4 {
  list-style-type: none;
}
ol.lst-kix_list_3-0 {
  list-style-type: none;
}
.lst-kix_list_1-1 > li {
  counter-increment: lst-ctn-kix_list_1-1;
}
.lst-kix_list_7-1 > li {
  counter-increment: lst-ctn-kix_list_7-1;
}
.lst-kix_list_9-1 > li {
  counter-increment: lst-ctn-kix_list_9-1;
}
ol.lst-kix_list_2-6.start {
  counter-reset: lst-ctn-kix_list_2-6 0;
}
.lst-kix_list_3-0 > li:before {
  content: '' counter(lst-ctn-kix_list_3-0, upper-latin) '. ';
}
ol.lst-kix_list_7-7.start {
  counter-reset: lst-ctn-kix_list_7-7 0;
}
.lst-kix_list_3-1 > li:before {
  content: '' counter(lst-ctn-kix_list_3-1, lower-latin) '. ';
}
.lst-kix_list_3-2 > li:before {
  content: '' counter(lst-ctn-kix_list_3-2, lower-roman) '. ';
}
.lst-kix_list_8-1 > li:before {
  content: '\u002022   ';
}
.lst-kix_list_4-0 > li {
  counter-increment: lst-ctn-kix_list_4-0;
}
.lst-kix_list_8-2 > li:before {
  content: '\u002022   ';
}
.lst-kix_list_6-0 > li {
  counter-increment: lst-ctn-kix_list_6-0;
}
.lst-kix_list_8-0 > li {
  counter-increment: lst-ctn-kix_list_8-0;
}
.lst-kix_list_3-5 > li:before {
  content: '' counter(lst-ctn-kix_list_3-5, lower-roman) '. ';
}
.lst-kix_list_3-4 > li:before {
  content: '' counter(lst-ctn-kix_list_3-4, lower-latin) '. ';
}
.lst-kix_list_3-3 > li:before {
  content: '' counter(lst-ctn-kix_list_3-3, decimal) '. ';
}
ol.lst-kix_list_3-5 {
  list-style-type: none;
}
ol.lst-kix_list_3-6 {
  list-style-type: none;
}
.lst-kix_list_8-0 > li:before {
  content: '(' counter(lst-ctn-kix_list_8-0, lower-latin) ') ';
}
ol.lst-kix_list_3-7 {
  list-style-type: none;
}
ol.lst-kix_list_3-8 {
  list-style-type: none;
}
.lst-kix_list_8-7 > li:before {
  content: '\u002022   ';
}
.lst-kix_list_3-8 > li:before {
  content: '' counter(lst-ctn-kix_list_3-8, lower-roman) '. ';
}
.lst-kix_list_8-5 > li:before {
  content: '\u002022   ';
}
.lst-kix_list_8-6 > li:before {
  content: '\u002022   ';
}
.lst-kix_list_2-0 > li {
  counter-increment: lst-ctn-kix_list_2-0;
}
.lst-kix_list_8-3 > li:before {
  content: '\u002022   ';
}
.lst-kix_list_3-6 > li:before {
  content: '' counter(lst-ctn-kix_list_3-6, decimal) '. ';
}
.lst-kix_list_3-7 > li:before {
  content: '' counter(lst-ctn-kix_list_3-7, lower-latin) '. ';
}
.lst-kix_list_8-4 > li:before {
  content: '\u002022   ';
}
ol.lst-kix_list_5-0.start {
  counter-reset: lst-ctn-kix_list_5-0 0;
}
ol.lst-kix_list_4-2.start {
  counter-reset: lst-ctn-kix_list_4-2 0;
}
ol.lst-kix_list_9-3.start {
  counter-reset: lst-ctn-kix_list_9-3 0;
}
.lst-kix_list_8-8 > li:before {
  content: '\u002022   ';
}
ol.lst-kix_list_2-2 {
  list-style-type: none;
}
ol.lst-kix_list_2-3 {
  list-style-type: none;
}
ol.lst-kix_list_2-4 {
  list-style-type: none;
}
ol.lst-kix_list_7-2.start {
  counter-reset: lst-ctn-kix_list_7-2 0;
}
ol.lst-kix_list_2-5 {
  list-style-type: none;
}
.lst-kix_list_4-4 > li {
  counter-increment: lst-ctn-kix_list_4-4;
}
ol.lst-kix_list_2-0 {
  list-style-type: none;
}
ol.lst-kix_list_2-1 {
  list-style-type: none;
}
.lst-kix_list_4-8 > li:before {
  content: '' counter(lst-ctn-kix_list_4-8, lower-roman) '. ';
}
ol.lst-kix_list_6-4.start {
  counter-reset: lst-ctn-kix_list_6-4 0;
}
.lst-kix_list_4-7 > li:before {
  content: '' counter(lst-ctn-kix_list_4-7, lower-latin) '. ';
}
ol.lst-kix_list_5-6.start {
  counter-reset: lst-ctn-kix_list_5-6 0;
}
ol.lst-kix_list_4-1.start {
  counter-reset: lst-ctn-kix_list_4-1 0;
}
.lst-kix_list_7-3 > li {
  counter-increment: lst-ctn-kix_list_7-3;
}
ol.lst-kix_list_4-8.start {
  counter-reset: lst-ctn-kix_list_4-8 0;
}
ol.lst-kix_list_3-3.start {
  counter-reset: lst-ctn-kix_list_3-3 0;
}
ol.lst-kix_list_2-6 {
  list-style-type: none;
}
ol.lst-kix_list_2-7 {
  list-style-type: none;
}
ol.lst-kix_list_2-8 {
  list-style-type: none;
}
ol.lst-kix_list_7-8.start {
  counter-reset: lst-ctn-kix_list_7-8 0;
}
ol.lst-kix_list_7-1.start {
  counter-reset: lst-ctn-kix_list_7-1 0;
}
.lst-kix_list_3-3 > li {
  counter-increment: lst-ctn-kix_list_3-3;
}
ol.lst-kix_list_6-3.start {
  counter-reset: lst-ctn-kix_list_6-3 0;
}
ol.lst-kix_list_5-5.start {
  counter-reset: lst-ctn-kix_list_5-5 0;
}
ol.lst-kix_list_8-0.start {
  counter-reset: lst-ctn-kix_list_8-0 0;
}
.lst-kix_list_7-0 > li:before {
  content: '' counter(lst-ctn-kix_list_7-0, decimal) '. ';
}
.lst-kix_list_2-2 > li {
  counter-increment: lst-ctn-kix_list_2-2;
}
ol.lst-kix_list_4-7.start {
  counter-reset: lst-ctn-kix_list_4-7 0;
}
.lst-kix_list_6-2 > li {
  counter-increment: lst-ctn-kix_list_6-2;
}
ol.lst-kix_list_5-0 {
  list-style-type: none;
}
.lst-kix_list_2-6 > li:before {
  content: '' counter(lst-ctn-kix_list_2-6, decimal) '. ';
}
.lst-kix_list_3-7 > li {
  counter-increment: lst-ctn-kix_list_3-7;
}
ol.lst-kix_list_5-1 {
  list-style-type: none;
}
ol.lst-kix_list_9-7.start {
  counter-reset: lst-ctn-kix_list_9-7 0;
}
ol.lst-kix_list_5-2 {
  list-style-type: none;
}
.lst-kix_list_2-4 > li:before {
  content: '' counter(lst-ctn-kix_list_2-4, lower-latin) '. ';
}
.lst-kix_list_2-8 > li:before {
  content: '' counter(lst-ctn-kix_list_2-8, lower-roman) '. ';
}
.lst-kix_list_7-1 > li:before {
  content: '(' counter(lst-ctn-kix_list_7-1, lower-latin) ') ';
}
.lst-kix_list_7-5 > li:before {
  content: '(' counter(lst-ctn-kix_list_7-5, lower-roman) ') ';
}
.lst-kix_list_9-6 > li {
  counter-increment: lst-ctn-kix_list_9-6;
}
.lst-kix_list_6-6 > li {
  counter-increment: lst-ctn-kix_list_6-6;
}
ol.lst-kix_list_5-4.start {
  counter-reset: lst-ctn-kix_list_5-4 0;
}
.lst-kix_list_7-3 > li:before {
  content: '(' counter(lst-ctn-kix_list_7-3, decimal) ') ';
}
.lst-kix_list_9-7 > li {
  counter-increment: lst-ctn-kix_list_9-7;
}
ol.lst-kix_list_5-1.start {
  counter-reset: lst-ctn-kix_list_5-1 0;
}
ol.lst-kix_list_5-7 {
  list-style-type: none;
}
.lst-kix_list_6-7 > li {
  counter-increment: lst-ctn-kix_list_6-7;
}
ol.lst-kix_list_5-8 {
  list-style-type: none;
}
ol.lst-kix_list_5-3 {
  list-style-type: none;
}
ol.lst-kix_list_5-4 {
  list-style-type: none;
}
ol.lst-kix_list_3-8.start {
  counter-reset: lst-ctn-kix_list_3-8 0;
}
ol.lst-kix_list_5-5 {
  list-style-type: none;
}
ol.lst-kix_list_5-6 {
  list-style-type: none;
}
.lst-kix_list_7-7 > li:before {
  content: '' counter(lst-ctn-kix_list_7-7, lower-latin) '. ';
}
.lst-kix_list_7-5 > li {
  counter-increment: lst-ctn-kix_list_7-5;
}
.lst-kix_list_9-5 > li {
  counter-increment: lst-ctn-kix_list_9-5;
}
.lst-kix_list_5-8 > li {
  counter-increment: lst-ctn-kix_list_5-8;
}
.lst-kix_list_4-0 > li:before {
  content: '' counter(lst-ctn-kix_list_4-0, decimal) '. ';
}
.lst-kix_list_3-8 > li {
  counter-increment: lst-ctn-kix_list_3-8;
}
ol.lst-kix_list_6-8.start {
  counter-reset: lst-ctn-kix_list_6-8 0;
}
.lst-kix_list_4-6 > li {
  counter-increment: lst-ctn-kix_list_4-6;
}
.lst-kix_list_4-4 > li:before {
  content: '(' counter(lst-ctn-kix_list_4-4, lower-latin) ') ';
}
ol.lst-kix_list_2-2.start {
  counter-reset: lst-ctn-kix_list_2-2 0;
}
ol.lst-kix_list_6-5.start {
  counter-reset: lst-ctn-kix_list_6-5 0;
}
.lst-kix_list_4-2 > li:before {
  content: '(' counter(lst-ctn-kix_list_4-2, lower-roman) ') ';
}
.lst-kix_list_4-6 > li:before {
  content: '' counter(lst-ctn-kix_list_4-6, decimal) '. ';
}
.lst-kix_list_9-3 > li:before {
  content: '(' counter(lst-ctn-kix_list_9-3, decimal) ') ';
}
ol.lst-kix_list_7-0.start {
  counter-reset: lst-ctn-kix_list_7-0 0;
}
.lst-kix_list_9-1 > li:before {
  content: '(' counter(lst-ctn-kix_list_9-1, lower-latin) ') ';
}
ol.lst-kix_list_4-0 {
  list-style-type: none;
}
ol.lst-kix_list_4-1 {
  list-style-type: none;
}
ol.lst-kix_list_4-2 {
  list-style-type: none;
}
ol.lst-kix_list_4-3 {
  list-style-type: none;
}
.lst-kix_list_9-7 > li:before {
  content: '' counter(lst-ctn-kix_list_9-7, lower-latin) '. ';
}
.lst-kix_list_2-4 > li {
  counter-increment: lst-ctn-kix_list_2-4;
}
ol.lst-kix_list_6-7.start {
  counter-reset: lst-ctn-kix_list_6-7 0;
}
ol.lst-kix_list_3-6.start {
  counter-reset: lst-ctn-kix_list_3-6 0;
}
.lst-kix_list_9-5 > li:before {
  content: '(' counter(lst-ctn-kix_list_9-5, lower-roman) ') ';
}
.lst-kix_list_5-3 > li {
  counter-increment: lst-ctn-kix_list_5-3;
}
ol.lst-kix_list_4-8 {
  list-style-type: none;
}
.lst-kix_list_7-4 > li {
  counter-increment: lst-ctn-kix_list_7-4;
}
.lst-kix_list_1-0 > li:before {
  content: '' counter(lst-ctn-kix_list_1-0, upper-latin) '. ';
}
ol.lst-kix_list_4-4 {
  list-style-type: none;
}
ol.lst-kix_list_4-5 {
  list-style-type: none;
}
.lst-kix_list_1-2 > li:before {
  content: '(' counter(lst-ctn-kix_list_1-2, lower-roman) ') ';
}
ol.lst-kix_list_2-0.start {
  counter-reset: lst-ctn-kix_list_2-0 0;
}
ol.lst-kix_list_4-6 {
  list-style-type: none;
}
ol.lst-kix_list_4-7 {
  list-style-type: none;
}
.lst-kix_list_1-4 > li:before {
  content: '\u002022   ';
}
ol.lst-kix_list_3-5.start {
  counter-reset: lst-ctn-kix_list_3-5 0;
}
.lst-kix_list_1-0 > li {
  counter-increment: lst-ctn-kix_list_1-0;
}
.lst-kix_list_1-6 > li:before {
  content: '\u002022   ';
}
li.li-bullet-0:before {
  margin-left: -36pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 36pt;
}
.lst-kix_list_2-0 > li:before {
  content: '' counter(lst-ctn-kix_list_2-0, decimal) '. ';
}
ol.lst-kix_list_2-1.start {
  counter-reset: lst-ctn-kix_list_2-1 0;
}
.lst-kix_list_4-5 > li {
  counter-increment: lst-ctn-kix_list_4-5;
}
ol.lst-kix_list_9-8.start {
  counter-reset: lst-ctn-kix_list_9-8 0;
}
.lst-kix_list_1-8 > li:before {
  content: '\u002022   ';
}
.lst-kix_list_2-2 > li:before {
  content: '' counter(lst-ctn-kix_list_2-2, lower-roman) '. ';
}
ol.lst-kix_list_5-2.start {
  counter-reset: lst-ctn-kix_list_5-2 0;
}
ol {
  margin: 0;
  padding: 0;
}
table td,
table th {
  padding: 0;
}
.c23 {
  border-right-style: solid;
  border-bottom-color: #000000;
  border-top-width: 0pt;
  border-right-width: 0pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 0pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 0pt;
  width: 233.8pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}
.c2 {
  margin-left: 41pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: justify;
  margin-right: 8.9pt;
  height: 12pt;
}
.c15 {
  margin-left: 23pt;
  padding-top: 0pt;
  padding-left: 18pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: justify;
  margin-right: 8.9pt;
}
.c11 {
  margin-left: 18pt;
  padding-top: 0pt;
  padding-left: 18pt;
  padding-bottom: 12pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c20 {
  margin-left: 54pt;
  padding-top: 0pt;
  padding-left: 18pt;
  padding-bottom: 12pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c4 {
  margin-left: 54pt;
  padding-top: 0pt;
  padding-left: 18pt;
  padding-bottom: 12pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c18 {
  padding-top: 0pt;
  padding-bottom: 12pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 12pt;
}
.c5 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-family: 'Arial';
  font-style: normal;
}
.c0 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-family: 'Arial';
  font-style: normal;
}
.c7 {
  -webkit-text-decoration-skip: none;
  color: #000000;
  text-decoration: underline;
  vertical-align: baseline;
  text-decoration-skip-ink: none;
  font-style: normal;
}
.c22 {
  padding-top: 0pt;
  padding-bottom: 12pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c16 {
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  font-family: 'Times New Roman';
  font-style: normal;
}
.c24 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: center;
}
.c17 {
  padding-top: 0pt;
  padding-bottom: 12pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c8 {
  -webkit-text-decoration-skip: none;
  font-weight: 400;
  text-decoration: underline;
  text-decoration-skip-ink: none;
  font-size: 10pt;
  font-family: 'Arial';
}
.c3 {
  padding-top: 0pt;
  padding-bottom: 12pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: center;
}
.c1 {
  font-size: 10pt;
  font-family: 'Arial';
  color: #000000;
  font-weight: 400;
}
.c14 {
  border-spacing: 0;
  border-collapse: collapse;
  margin-right: auto;
}
.c12 {
  font-size: 10pt;
  font-family: 'Arial';
  font-weight: 400;
}
.c6 {
  font-size: 10pt;
  font-family: 'Arial';
  font-weight: 700;
}
.c26 {
  background-color: #ffffff;
  max-width: 468pt;
  padding: 72pt 72pt 72pt 72pt;
}
.c10 {
  margin-left: 5pt;
  margin-right: 8.9pt;
}
.c9 {
  padding: 0;
  margin: 0;
}
.c13 {
  color: #000000;
}
.c19 {
  margin-left: 36pt;
}
.c25 {
  height: 0pt;
}
.c27 {
  font-style: italic;
}
.c21 {
  height: 12pt;
}
.title {
  padding-top: 24pt;
  color: #000000;
  font-weight: 700;
  font-size: 36pt;
  padding-bottom: 6pt;
  font-family: 'Times New Roman';
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.subtitle {
  padding-top: 18pt;
  color: #666666;
  font-size: 24pt;
  padding-bottom: 4pt;
  font-family: 'Georgia';
  line-height: 1;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}
li {
  color: #000000;
  font-size: 12pt;
  font-family: 'Times New Roman';
}
p {
  margin: 0;
  color: #000000;
  font-size: 12pt;
  font-family: 'Times New Roman';
}
h1 {
  padding-top: 24pt;
  color: #000000;
  font-weight: 700;
  font-size: 24pt;
  padding-bottom: 6pt;
  font-family: 'Times New Roman';
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h2 {
  padding-top: 18pt;
  color: #000000;
  font-weight: 700;
  font-size: 18pt;
  padding-bottom: 4pt;
  font-family: 'Times New Roman';
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h3 {
  padding-top: 14pt;
  color: #000000;
  font-weight: 700;
  font-size: 14pt;
  padding-bottom: 4pt;
  font-family: 'Times New Roman';
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h4 {
  padding-top: 12pt;
  color: #000000;
  font-weight: 700;
  font-size: 12pt;
  padding-bottom: 2pt;
  font-family: 'Times New Roman';
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h5 {
  padding-top: 11pt;
  color: #000000;
  font-weight: 700;
  font-size: 11pt;
  padding-bottom: 2pt;
  font-family: 'Times New Roman';
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h6 {
  padding-top: 10pt;
  color: #000000;
  font-weight: 700;
  font-size: 10pt;
  padding-bottom: 2pt;
  font-family: 'Times New Roman';
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
`;

interface Props {
  contractorName: string;
  contractorSignature: string;
}

export const BaaText = forwardRef<HTMLDivElement, Props>(
  (props, ref?: ForwardedRef<HTMLDivElement>) => {
    const { contractorName, contractorSignature = "" } = props;

    const contractorSignatureImage = contractorSignature && (
      <img
        src={contractorSignature}
        alt="contractor signature"
        style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
      />
    );

    const currentDate = (
      <span className="c0">
        <br />
        Date: {formatDateWithFullMonth(new Date())}
      </span>
    );

    return (
      <div ref={ref} style={{ background: "white" }}>
        <style>{css}</style>
        <div>
          <p className="c18">
            <span className="c13 c16" />
          </p>
        </div>
        <p className="c3">
          <span className="c5">BUSINESS ASSOCIATE AGREEMENT</span>
        </p>
        <p className="c17 c10">
          <span className="c1">This BUSINESS ASSOCIATE AGREEMENT (this &ldquo;</span>
          <span className="c6 c13">Agreement</span>
          <span className="c1">
            &rdquo;) is entered into as of the date of the Contractor&rsquo;s signature, below (the
            &ldquo;
          </span>
          <span className="c6 c13">Effective Date</span>
          <span className="c1">
            &rdquo;) by and between Twomagnets LLC d/b/a Clipboard, a Delaware limited liability
            company
          </span>
          <span className="c1">&nbsp;(&ldquo;</span>
          <span className="c6">Clipboard</span>
          <span className="c1">&rdquo;) </span>
          <span className="c1">
            and the contractor who has signed below or otherwise accepted this Agreement
          </span>
          <span className="c1">&nbsp;(&ldquo;</span>
          <span className="c6">Contractor</span>
          <span className="c1">&rdquo;).</span>
          <span className="c1">&nbsp;</span>
          <span className="c12">Clipboard</span>
          <span className="c1">&nbsp;and Contractor</span>
          <span className="c1">
            &nbsp;may hereinafter be referred to, individually, as a &ldquo;
          </span>
          <span className="c6 c13">Party</span>
          <span className="c1">&rdquo; and, collectively, as the &ldquo;</span>
          <span className="c6 c13">Parties</span>
          <span className="c0">.&rdquo;</span>
        </p>
        <p className="c3 c10">
          <span className="c7 c6">Recitals</span>
        </p>
        <ol className="c9 lst-kix_list_1-0 start" start={1}>
          <li className="c15 li-bullet-0">
            <span className="c1">WHEREAS,</span>
            <span className="c6 c13">&nbsp;</span>
            <span className="c1">
              Clipboard operates a technology-enabled platform (the &ldquo;
            </span>
            <span className="c6 c13">CBH Marketplace</span>
            <span className="c1">
              &rdquo;) that, among other things, allows healthcare facilities (each, a &ldquo;
            </span>
            <span className="c6 c13">Facility</span>
            <span className="c1">&rdquo;) to post open </span>
            <span className="c12">visit</span>
            <span className="c1">
              s and independent healthcare professionals to view and sign up to work those{" "}
            </span>
            <span className="c12">visit</span>
            <span className="c0">s; </span>
          </li>
        </ol>
        <p className="c2">
          <span className="c0" />
        </p>
        <ol className="c9 lst-kix_list_1-0" start={2}>
          <li className="c15 li-bullet-0">
            <span className="c1">
              WHEREAS, Contractor is a healthcare professional and wishes to access the CBH
              Marketplace to view and, from time to time, sign up to work{" "}
            </span>
            <span className="c12">visit</span>
            <span className="c1">s</span>
            <span className="c1">
              &nbsp;posted by Facilities pursuant to an Independent Contractor Agreement (the
              &ldquo;
            </span>
            <span className="c6 c13">ICA</span>
            <span className="c0">&rdquo;) between the Parties;</span>
          </li>
        </ol>
        <p className="c2">
          <span className="c0" />
        </p>
        <ol className="c9 lst-kix_list_1-0" start={3}>
          <li className="c15 li-bullet-0">
            <span className="c1">
              WHEREAS, Facilities are &ldquo;covered entities,&rdquo; as defined under the Health
              Insurance Portability and Accountability Act of 1996, Public Law 104-191, as amended
              by the HITECH Act (as defined below) and the related regulations promulgated by HHS
              (as defined below) (collectively, &ldquo;
            </span>
            <span className="c6 c13">HIPAA</span>
            <span className="c0">&rdquo;);</span>
          </li>
        </ol>
        <p className="c2">
          <span className="c0" />
        </p>
        <ol className="c9 lst-kix_list_1-0" start={4}>
          <li className="c15 li-bullet-0">
            <span className="c1">WHEREAS,</span>
            <span className="c6 c13">&nbsp;</span>
            <span className="c1">
              to the extent that Contractor&rsquo;s performance services at a Facility during any{" "}
            </span>
            <span className="c12">visit</span>
            <span className="c0">
              &nbsp;booked through the CBH Marketplace causes Contractor and/or Clipboard to meet,
              with respect to that Facility, the definition of &ldquo;business associate&rdquo; set
              forth at 45 C.F.R. &sect; 160.103, the Parties are committed to complying with their
              respective obligations under HIPAA, including, without limitation, the Privacy Rule
              (as defined below) and the Security Rule (as defined below) promulgated thereunder.
            </span>
          </li>
        </ol>
        <p className="c2">
          <span className="c0" />
        </p>
        <p className="c3">
          <span className="c7 c6">Agreement</span>
        </p>
        <p className="c17">
          <span className="c0">
            NOW THEREFORE, in consideration of the mutual covenants, terms, and conditions set forth
            herein, and for other good and valuable consideration, the sufficiency of which is
            hereby acknowledged, the Parties agree as follows:
          </span>
        </p>
        <ol className="c9 lst-kix_list_4-0 start" start={1}>
          <li className="c11 li-bullet-0">
            <span className="c7 c6">Definitions.</span>
            <span className="c0">
              &nbsp;For purposes of this Agreement, the Parties give the following meaning to each
              of the terms in this Section 1. Any capitalized term used in this Agreement, but not
              otherwise defined, has the meaning given to that term in the Privacy Rule or pertinent
              law.
            </span>
          </li>
        </ol>
        <ol className="c9 lst-kix_list_4-1 start" start={1}>
          <li className="c4 li-bullet-0">
            <span className="c0">&ldquo;</span>
            <span className="c5">Breach</span>
            <span className="c0">
              &rdquo; means the acquisition, access, use, or disclosure of PHI in a manner not
              permitted under the Privacy Rule which compromises the security or privacy of the PHI,
              as defined in 45 CFR &sect;164.402.
            </span>
          </li>
          <li className="c4 li-bullet-0">
            <span className="c0">&ldquo;</span>
            <span className="c5">Breach Notification Rule</span>
            <span className="c0">
              &rdquo; means the portion of HIPAA set forth in Subpart D of 45 CFR Part 164.
            </span>
          </li>
          <li className="c4 li-bullet-0">
            <span className="c0">&ldquo;</span>
            <span className="c5">Data Aggregation</span>
            <span className="c0">
              &rdquo; means, with respect to PHI created or received by Contractor in their capacity
              as the &ldquo;business associate,&rdquo; the combining of such PHI by Contractor with
              the PHI received by Contractor in their capacity as a business associate of one or
              more other &ldquo;covered entity&rdquo; under HIPAA, to permit data analyses that
              relate to the Health Care Operations (defined below) of the respective covered
              entities. The meaning of &ldquo;data aggregation&rdquo; in this Agreement shall be
              consistent with the meaning given to that term in the Privacy Rule.
            </span>
          </li>
          <li className="c4 li-bullet-0">
            <span className="c0">&ldquo;</span>
            <span className="c5">Designated Record Set</span>
            <span className="c0">
              &rdquo; has the meaning given to such term under the Privacy Rule, including 45 CFR
              &sect;164.501.B.
            </span>
          </li>
          <li className="c4 li-bullet-0">
            <span className="c0">&ldquo;</span>
            <span className="c5">De-Identify</span>
            <span className="c0">
              &rdquo; means to alter the PHI such that the resulting information meets the
              requirements described in 45 CFR &sect;&sect;164.514(a) and (b).
            </span>
          </li>
          <li className="c4 li-bullet-0">
            <span className="c0">&ldquo;</span>
            <span className="c5">Electronic PHI</span>
            <span className="c0">
              &rdquo; means any PHI maintained in or transmitted by electronic media as defined in
              45 CFR &sect;160.103.
            </span>
          </li>
          <li className="c4 li-bullet-0">
            <span className="c0">&ldquo;</span>
            <span className="c5">Health Care Operations</span>
            <span className="c0">
              &rdquo; has the meaning given to that term in 45 CFR &sect;164.501.
            </span>
          </li>
          <li className="c4 li-bullet-0">
            <span className="c0">&ldquo;</span>
            <span className="c5">HHS</span>
            <span className="c0">
              &rdquo; means the U.S. Department of Health and Human Services.
            </span>
          </li>
          <li className="c4 li-bullet-0">
            <span className="c0">&ldquo;</span>
            <span className="c5">HITECH Act</span>
            <span className="c0">
              &rdquo; means the Health Information Technology for Economic and Clinical Health Act,
              enacted as part of the American Recovery and Reinvestment Act of 2009, Public Law
              111-005.
            </span>
          </li>
          <li className="c4 li-bullet-0">
            <span className="c0">&ldquo;</span>
            <span className="c5">Individual</span>
            <span className="c0">
              &rdquo; has the same meaning given to that term in 45 CFR &sect;&sect;164.501 and
              160.130 and includes a person who qualifies as a personal representative in accordance
              with 45 CFR &sect;164.502(g).
            </span>
          </li>
          <li className="c4 li-bullet-0">
            <span className="c0">&ldquo;</span>
            <span className="c5">Privacy Rule</span>
            <span className="c0">
              &rdquo; means that portion of HIPAA set forth in 45 CFR Part 160 and Part 164,
              Subparts A and E.
            </span>
          </li>
          <li className="c4 li-bullet-0">
            <span className="c0">&ldquo;</span>
            <span className="c5">Protected Health Information</span>
            <span className="c0">&rdquo; or &ldquo;</span>
            <span className="c5">PHI</span>
            <span className="c0">
              &rdquo; has the meaning given to the term &ldquo;protected health information&rdquo;
              in 45 CFR &sect;&sect;164.501 and 160.103, limited to the information created or
              received by Contractor from or on behalf of a Facility or Clipboard.
            </span>
          </li>
          <li className="c4 li-bullet-0">
            <span className="c0">&ldquo;</span>
            <span className="c5">Security Incident</span>
            <span className="c0">
              &rdquo; means the attempted or successful unauthorized access, use, disclosure,
              medication, or destruction of information or interference with system operators in an
              information system.
            </span>
          </li>
          <li className="c4 li-bullet-0">
            <span className="c0">&ldquo;</span>
            <span className="c5">Security Rule</span>
            <span className="c0">
              &rdquo; means the Security Standards for the Protection of Electronic Health
              Information provided in 45 CFR Part 160 &amp; Part 164, Subparts A and C.
            </span>
          </li>
          <li className="c4 li-bullet-0">
            <span className="c0">&ldquo;</span>
            <span className="c5">Unsecured Protected Health Information</span>
            <span className="c0">&rdquo; or &ldquo;</span>
            <span className="c5">Unsecured PHI</span>
            <span className="c0">
              &rdquo; means any &ldquo;protected health information&rdquo; as defined in 45 CFR
              &sect;&sect;164.501 and 160.103 that is not rendered unusable, unreadable or
              indecipherable to unauthorized individuals through the use of a technology or
              methodology specified by the HHS Secretary in the guidance issued pursuant to the
              HITECH Act and codified at 42 USC &sect;17932(h).
            </span>
          </li>
        </ol>
        <ol className="c9 lst-kix_list_4-0" start={2}>
          <li className="c11 li-bullet-0" id="h.gjdgxs">
            <span className="c7 c6">Applicability.</span>
            <span className="c5">&nbsp;</span>
            <span className="c0">
              It is the express understanding and intent of the Parties that this Agreement shall be
              applicable only if and to the extent that Contractor meets, with respect to Clipboard
              and/or one or more Facilities, the definition of &ldquo;business associate&rdquo; set
              forth at 45 C.F.R. &sect; 160.103, and shall otherwise impose no obligations and
              confer no rights upon the Parties whatsoever.
            </span>
          </li>
          <li className="c11 li-bullet-0">
            <span className="c7 c6">Use and Disclosure of PHI.</span>
          </li>
        </ol>
        <ol className="c9 lst-kix_list_4-1 start" start={1}>
          <li className="c4 li-bullet-0">
            <span className="c0">
              Except as otherwise provided in this Agreement, Contractor may use or disclose PHI as
              reasonably necessary to provide the services described in the ICA to Facility, and to
              undertake other actives of Contractor permitted or required of Contractor by this
              Agreement or as required by law.
            </span>
          </li>
          <li className="c4 li-bullet-0">
            <span className="c0">
              Except as otherwise limited by this Agreement or federal or state law, Clipboard
              authorizes Contractor to use the PHI in their possession for the proper management and
              administration of Contractor&rsquo;s business and to carry out their legal
              responsibilities and obligations pursuant to the ICA. Contractor may disclose PHI for
              their proper management and administration, provided that: (i) the disclosures are
              required by law; or (ii) Contractor obtains, in writing, prior to making any
              disclosure to a third party (a) reasonable assurances from this third party that the
              PHI will be held confidential as provided under this Agreement and used or further
              disclosed only as required by law or for the purpose for which it was disclosed to
              this third party and (b) an agreement from this third party to notify Contractor
              immediately of any breaches of the confidentiality of the PHI, to the extent it has
              knowledge of the breach.
            </span>
          </li>
          <li className="c4 li-bullet-0">
            <span className="c0">
              Contractor will not use or disclose PHI in a manner other than as provided in this
              Agreement, as permitted under the Privacy Rule, or as required by law. Contractor will
              use or disclose PHI, to the extent practicable, as a limited data set or limited to
              the minimum necessary amount of PHI to carry out the intended purpose of the use or
              disclosure, in accordance with Section 13405(b) of the HITECH Act (codified at 42 USC
              &sect;17935(b)) and any of the act&rsquo;s implementing regulations adopted by HHS,
              for each use or disclosure of PHI.
            </span>
          </li>
          <li className="c4 li-bullet-0">
            <span className="c0">
              Contractor shall report to the Clipboard any use or disclosure of protected health
              information not provided for by this agreement of which they become aware, including
              breaches of unsecured protected health information as required by 45 C.F.R.
              &sect;164.410.
            </span>
          </li>
          <li className="c4 li-bullet-0">
            <span className="c0">
              Contractor may use PHI to report violations of law to appropriate Federal and State
              authorities, consistent with 45 CFR &sect;164.502(j)(1).
            </span>
          </li>
        </ol>
        <ol className="c9 lst-kix_list_4-0" start={4}>
          <li className="c11 li-bullet-0">
            <span className="c7 c6">Safeguards Against Misuse of PHI.</span>
            <span className="c0">
              &nbsp;Contractor will use appropriate safeguards to prevent the use or disclosure of
              PHI other than as provided by the ICA or this Agreement and Contractor agrees to
              implement administrative, physical, and technical safeguards that reasonably and
              appropriately protect the conductivity, integrity and availability of the Electronic
              PHI that it creates, receives, maintains or transmits on behalf of a Facility or
              Clipboard. Contractor agrees to take reasonable steps to ensure compliance with this
              Agreement and to ensure that the actions or omissions of their employees or agents do
              not cause Contractor to breach the terms of this Agreement.
            </span>
          </li>
          <li className="c11 li-bullet-0">
            <span className="c7 c6">Reporting Disclosures of PHI and Security Incidents.</span>
            <span className="c0">
              &nbsp;Contractor will report to Clipboard in writing any use or disclosure of PHI not
              provided for by this Agreement of which they become aware and Contractor agrees to
              report to Clipboard any Security Incident affecting Electronic PHI of a Facility of
              which they become aware. Contractor agrees to report any such event within three
              business days of becoming aware of the event.
            </span>
          </li>
        </ol>
        <p className="c17 c19">
          <span className="c7 c6">Reporting Breaches of Unsecured PHI.</span>
          <span className="c0">
            &nbsp;Contractor will notify Clipboard in writing promptly upon the discovery of any
            Breach of Unsecured PHI in accordance with the requirements set forth in 45 CFR
            &sect;164.410, but in no case later than 30 calendar days after discovery of a Breach.
            Contractor will reimburse Clipboard for any costs incurred by it in complying with the
            requirements of Subpart D of 45 CFR &sect;164 that are imposed on Clipboard as a result
            of a Breach committed by Contractor.
          </span>
        </p>
        <ol className="c9 lst-kix_list_4-0" start={6}>
          <li className="c11 li-bullet-0">
            <span className="c7 c6">Mitigation of Disclosures of PHI.</span>
            <span className="c0">
              &nbsp;Contractor will take reasonable measures to mitigate, to the extent practicable,
              any harmful e&#64256;ect that is known to Contractor of any use or disclosure of PHI
              by Contractor or their agents or subcontractors in violation of the requirements of
              this Agreement.
            </span>
          </li>
          <li className="c11 li-bullet-0">
            <span className="c7 c6">Agreements with Agents or Subcontractors.</span>
            <span className="c0">
              &nbsp;Contractor will ensure that any of their agents or subcontractors that have
              access to, or to which Contractor provides, PHI governed by this Agreement agree in
              writing to the restrictions and conditions concerning uses and disclosures of PHI
              contained in this Agreement and agree to implement reasonable and appropriate
              safeguards to protect any Electronic PHI that it creates, receives, maintains or
              transmits on behalf of Contractor. Contractor shall ensure that all such written
              agreements provide substantially the same level of privacy and security as this
              Agreement.
            </span>
          </li>
          <li className="c11 li-bullet-0">
            <span className="c7 c6">Access to PHI by Individuals.</span>
          </li>
        </ol>
        <ol className="c9 lst-kix_list_4-1 start" start={1}>
          <li className="c4 li-bullet-0">
            <span className="c0">
              Upon request, Contractor agrees to furnish Facilities with copies of the PHI
              maintained by Contractor in a Designated Record Set in order to enable those
              Facilities to respond to an Individual&rsquo;s request for access to PHI under 45 CFR
              &sect;164.524.
            </span>
          </li>
          <li className="c4 li-bullet-0">
            <span className="c0">
              In the event any Individual or personal representative requests access to the
              Individual&rsquo;s PHI directly from Contractor, Contractor will forward that request
              to the relevant Facility. Any disclosure of, or decision not to disclose, the PHI
              requested by an Individual or a personal representative and compliance with the
              requirements applicable to an Individual&rsquo;s right to obtain access to PHI shall
              be the sole responsibility of the relevant Facility.
            </span>
          </li>
        </ol>
        <ol className="c9 lst-kix_list_4-0" start={9}>
          <li className="c11 li-bullet-0">
            <span className="c7 c6">Amendment of PHI.</span>
          </li>
        </ol>
        <ol className="c9 lst-kix_list_5-1 start" start={1}>
          <li className="c4 li-bullet-0">
            <span className="c0">
              Upon request and instruction from Clipboard or a Facility, Contractor will amend PHI
              or a record about an Individual in a Designated Record Set that is maintained by, or
              otherwise within the possession of, Contractor as directed by Clipboard or a Facility
              in accordance with procedures established by 45 CFR &sect;164.526.
            </span>
          </li>
          <li className="c4 li-bullet-0">
            <span className="c0">
              In the event that any Individual requests that Contractor amend such
              Individual&rsquo;s PHI or record in a Designated Record Set, Contractor will forward
              this request to the relevant Facility. Any amendment of, or decision not to amend, the
              PHI or record as requested by an Individual and compliance with the requirements
              applicable to an Individual&rsquo;s right to request an amendment of PHI will be the
              sole responsibility of the relevant Facility.
            </span>
          </li>
        </ol>
        <ol className="c9 lst-kix_list_4-0" start={10}>
          <li className="c11 li-bullet-0">
            <span className="c6 c7">Documentation of Disclosures.</span>
            <span className="c0">
              &nbsp;Contractor will document any disclosures of PHI made by it to account for such
              disclosures as required by 45 CFR &sect;164.528(a). Contractor also will make
              available information related to such disclosures as is reasonably required for
              Clipboard or a Facility to respond to a request for an accounting of disclosures in
              accordance with 45 CFR &sect;164.528. In the event an Individual delivers the initial
              request for an accounting directly to Contractor, Contractor will forward such request
              to the relevant Facility. Any response to such request, or decision not to respond,
              will be the sole responsibility of the relevant Facility.
            </span>
          </li>
          <li className="c11 li-bullet-0">
            <span className="c7 c6">Availability of Books and Records.</span>
            <span className="c0">
              &nbsp;Upon request by the Secretary, Contractor agrees to make available to the
              Secretary, Contractor&rsquo;s internal practices, books, agreements, records, and
              policies and procedures relating to the use and disclosure of PHI for use by the
              Secretary in determining whether Clipboard or a Facility is in compliance with HIPAA.
              Contractor may delay complying with a request of the Secretary as to this provision
              while Contractor makes reasonable efforts to ascertain their applicable legal
              responsibilities with respect to this Section 11
            </span>
          </li>
          <li className="c11 li-bullet-0">
            <span className="c7 c6">Obligations of Clipboard.</span>
          </li>
        </ol>
        <ol className="c9 lst-kix_list_6-1 start" start={1}>
          <li className="c4 li-bullet-0">
            <span className="c0">
              Clipboard shall notify Contractor of any limitations in the Clipboard&rsquo;s Notice
              of Privacy Practices, to the extent such limitations may affect Contractor&#39;s use
              or disclosure of PHI.
            </span>
          </li>
          <li className="c4 li-bullet-0">
            <span className="c0">
              Clipboard shall notify Contractor of any changes in, or revocation of, permission
              granted by any Individual to use or disclose PHI, to the extent such changes or
              revocations may affect Contractor&#39;s use or disclosure of PHI.
            </span>
          </li>
          <li className="c4 li-bullet-0">
            <span className="c0">
              Clipboard shall notify Contractor of any restrictions on the use or disclosure of PHI
              to which Clipboard has agreed in accordance with 45 CFR &sect; 164.522, to the extent
              such restrictions may affect Contractor&#39;s use or disclosure of PHI.
            </span>
          </li>
          <li className="c4 li-bullet-0">
            <span className="c0">
              Except as provided in Section 3 of this Agreement, Clipboard shall not request
              Contractor to use or disclose PHI in any manner that would not be permissible under
              the HIPAA Rules if made by Clipboard.
            </span>
          </li>
        </ol>
        <ol className="c9 lst-kix_list_4-0" start={13}>
          <li className="c11 li-bullet-0">
            <span className="c7 c6">Term and Termination.</span>
          </li>
        </ol>
        <ol className="c9 lst-kix_list_7-1 start" start={1}>
          <li className="c4 li-bullet-0">
            <span className="c0">
              This Agreement will become the Effective Date and, unless terminated earlier in
              accordance with this Section 13, will continue in e&#64256;ect until all obligations
              of the Parties have been met under the ICA and under this Agreement, at which time
              this Agreement will expire automatically.
            </span>
          </li>
          <li className="c4 li-bullet-0">
            <span className="c0">Either Party (the &ldquo;</span>
            <span className="c5">Terminating Party</span>
            <span className="c0">
              &rdquo;) may terminate immediately this Agreement, the ICA, and any other related
              agreements if the other Party breaches a material term of this Agreement and fails to
              cure that breach within thirty (30) days after written notice from Clipboard thereof,
              or if that breach cannot reasonably be cured. The Terminating Party may report the
              breach to the Secretary of HHS if termination is not feasible.
            </span>
          </li>
          <li className="c4 li-bullet-0">
            <span className="c0">
              Except as provided in this Section 13(c), upon termination of this Agreement for any
              reason, Contractor shall return or destroy all PHI, including electronic PHI, governed
              by this Agreement. This provision shall also apply to PHI that is in the possession of
              subcontractors of Contractor. Contractor shall retain no copies of the PHI. If
              Contractor determines that returning or destroying the PHI is infeasible, Contractor
              shall provide to Clipboard notification of the conditions that make return or
              destruction infeasible and extend the protections of this Agreement to such PHI and
              limit further uses and disclosures of such PHI to those purposes that make return or
              destruction infeasible, for so long as Contractor retains such PHI.
            </span>
          </li>
          <li className="c20 li-bullet-0">
            <span className="c0">
              The following sections shall survive termination or expiration of this Agreement:
              Section 13 (Termination) and Section 14 (Miscellaneous).
            </span>
          </li>
        </ol>
        <ol className="c9 lst-kix_list_4-0" start={14}>
          <li className="c11 li-bullet-0">
            <span className="c7 c6">Miscellaneous.</span>
          </li>
        </ol>
        <ol className="c9 lst-kix_list_9-1 start" start={1}>
          <li className="c4 li-bullet-0">
            <span className="c5">Regulatory References</span>
            <span className="c0">
              . A reference in this Agreement to a section in the HIPAA Rules means the section as
              in effect or amended, if such amendment is final and has taken effect.
            </span>
          </li>
          <li className="c4 li-bullet-0">
            <span className="c5">Assignment. </span>
            <span className="c0">
              Clipboard may freely assign its rights and obligations under this Agreement at any
              time by written notice to Contractor. Contractor may not assign this Agreement without
              the prior written consent of Clipboard, and any attempt to do so will be void. Subject
              to the limits on assignment stated above, this Agreement will inure to the benefit of,
              be binding on, and be enforceable against each of the Parties and their respective
              successors and permitted assigns.
            </span>
            <span className="c5">&nbsp;</span>
          </li>
          <li className="c4 li-bullet-0">
            <span className="c5">Severability. </span>
            <span className="c0">
              If any provision of this Agreement shall be found invalid or unenforceable, the
              remainder of this Agreement shall be interpreted so as best to reasonably effect the
              intent of the Parties.
            </span>
          </li>
          <li className="c4 li-bullet-0">
            <span className="c5">Waiver</span>
            <span className="c0">
              . A waiver by either Party of any requirement of this Agreement shall not be construed
              as a continuing waiver, a waiver of any other requirement, or a waiver of any right or
              remedy otherwise available.
            </span>
          </li>
          <li className="c4 li-bullet-0">
            <span className="c5">Governing Law. </span>
            <span className="c0">
              This Agreement shall be governed by and construed in accordance with the laws of the
              jurisdiction specified in the governing law provision of the ICA or, if none is
              specified, the State of Delaware.
            </span>
          </li>
          <li className="c4 li-bullet-0">
            <span className="c5">Notices.</span>
            <span className="c0">
              &nbsp;Any notice, demand, or request given by either Party with respect to this
              Agreement shall be in writing and shall be effective only if it is delivered to the
              other Party at in accordance with the notice provisions of the ICA.
            </span>
          </li>
          <li className="c4 li-bullet-0">
            <span className="c5">Counterparts; Electronic Signatures. </span>
            <span className="c0">
              This Agreement is executed in and may be executed in counterparts, each of which shall
              be deemed an original, but all of which together shall constitute one and the same
              instrument. Signatures of the Parties on this Agreement or on any document or
              instrument delivered pursuant to this Agreement via electronic transmission shall be
              deemed to be original signatures and shall be sufficient to bind the Parties.
            </span>
          </li>
          <li className="c4 li-bullet-0">
            <span className="c5">Amendment.</span>
            <span className="c0">
              &nbsp;Any amendments or modifications to this Agreement shall only be effective if
              made in writing and signed by both Parties. The Parties agree to negotiate in good
              faith to amend this Agreement from time to time as is necessary to comply with any new
              or revised final requirements of the HIPAA Rules, HIPAA, and HITECH.
            </span>
          </li>
          <li className="c4 li-bullet-0">
            <span className="c5">Entire Agreement. </span>
            <span className="c0">
              This Agreement sets forth all of the promises, agreements and conditions regarding its
              subject matter, supersedes all prior understandings (whether written, oral or
              otherwise) pertaining thereto and constitutes the entire Agreement between the Parties
              regarding the subject matter hereof.
            </span>
          </li>
        </ol>
        <p className="c22">
          <span className="c6">IN WITNESS WHEREOF, </span>
          <span className="c0">
            the Parties have caused this Agreement to be duly executed as of the Effective Date.
          </span>
        </p>
        <table className="c14">
          <tbody>
            <tr className="c25">
              <td className="c23" colSpan={1} rowSpan={1}>
                <p className="c22">
                  <span className="c0">
                    Twomagnets LLC d/b/a Clipboard
                    <br />
                  </span>
                </p>
                <p className="c22">
                  <span className="c12">By: </span>
                  <span className="c8 c27">/s/ Wei Deng</span>
                  <span className="c8">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                  <span className="c0">
                    <br />
                    Name:Wei Deng
                    <br />
                    Title: Co-CEO <br />
                  </span>
                </p>
              </td>
              <td className="c23" colSpan={1} rowSpan={1}>
                <p className="c18">
                  <span className="c0" />
                </p>
              </td>
            </tr>
            <tr className="c25">
              <td className="c23" colSpan={1} rowSpan={1} style={{ position: "relative" }}>
                <p className="c22">
                  <span className="c0">Contractor</span>
                </p>
                <p className="c18">
                  <span className="c7 c12" />
                </p>
                <p className="c18">
                  <span className="c7 c12" />
                </p>
                <p className="c22">
                  <span className="c8">
                    {contractorSignatureImage}
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                  <span className="c0">
                    <br />
                    Print Name: {contractorName}
                  </span>
                  {currentDate}
                </p>
              </td>
              <td className="c23" colSpan={1} rowSpan={1}>
                <p className="c18">
                  <span className="c0" />
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p className="c17 c21">
          <span className="c0" />
        </p>
        <div>
          <p className="c21 c24">
            <span className="c16 c13" />
          </p>
        </div>
      </div>
    );
  }
);

BaaText.displayName = "BaaText";
